<template>
    <div class="waiting-form full-width" v-if="translates && translates[langUrl]">
        <div v-if="isDesktop" class="waiting-form__container">
            <div class="waiting-form__form" v-if="!formSended">
                <div class="waiting-form__form__container">
                    <div v-if="!custom || !blogger.avatar" class="waiting-form__form__logo"></div>
                    <div v-if="custom && blogger.avatar" class="waiting-form__form__custom-logo">
                        <div class="waiting-form__form__custom-logo__avatar" :style="`background-image: url(${ imageSrc(blogger.avatar) })`"></div>
                    </div>
                    <!-- <div class="waiting-form__form__title">
                        {{ translates[langUrl].formTitle[lang] }}
                    </div> -->
                    <div class="waiting-form__form__text__title">
                        {{ translates[langUrl].picture_title_part1[lang] }}
                        {{ blogger.firstname }} {{ blogger.lastname }}
                        ({{ blogger.social }})
                        {{ translates[langUrl].picture_title_part2[lang] }}</div>
                    <div class="waiting-form__form__text__description" v-html="translates[langUrl].picture_description[lang]"></div>
                    <ValidationObserver ref="validator" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="waiting.name"
                                class="form"
                                :placeholder="translates[langUrl].placeholder_firstname[lang]"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BasePhoneNumberInput
                                v-model="waiting.phone"
                                @update="number($event)"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="waiting.email"
                                class="form"
                                :placeholder="translates[langUrl].placeholder_email[lang]"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                    <BaseButton
                        class="button-fill"
                        style="width:100%; margin-top: 12px;"
                        @click="send">{{ translates[langUrl].button_send[lang] }}</BaseButton>
                </div>
            </div>
            <div v-if="formSended" class="waiting-form__sended">
                <div v-if="!custom || !blogger.avatar" class="waiting-form__form__logo"></div>
                <div v-if="custom && blogger.avatar" class="waiting-form__form__custom-logo">
                    <div class="waiting-form__form__custom-logo__avatar" :style="`background-image: url(${ imageSrc(blogger.avatar) })`"></div>
                    <div class="waiting-form__form__custom-logo__name">{{ blogger.firstname }} {{ blogger.lastname }}</div>
                </div>
                <div class="waiting-form__sended__text">
                    {{ translates[langUrl].formSended_part_1[lang] }}
                    {{ waiting.name }}!
                    {{ translates[langUrl].formSended_part_2[lang] }}
                    {{ blogger.firstname }} {{ blogger.lastname }}!
                    {{ translates[langUrl].formSended_part_3[lang] }}
                </div>
                <BaseButton
                    class="button-fill"
                    style="width:100%"
                    @click="formReverse"
                >{{ translates[langUrl].button_oneMore[lang] }}</BaseButton>
            </div>
        </div>
        <div v-else class="waiting-form__mobile">
            <div class="waiting-form__form__text">
                <div v-if="!custom || !blogger.avatar" class="waiting-form__form__logo"></div>
                <div v-if="custom && blogger.avatar" class="waiting-form__form__custom-logo">
                    <div class="waiting-form__form__custom-logo__avatar" :style="`background-image: url(${ imageSrc(blogger.avatar) })`"></div>
                </div>
                <div class="waiting-form__form__text__title">
                    {{ translates[langUrl].picture_title_part1[lang] }}
                    {{ blogger.firstname }} {{ blogger.lastname }}
                    ({{ blogger.social }})
                    {{ translates[langUrl].picture_title_part2[lang] }}
                </div>
                    <div class="waiting-form__form__text__description" v-html="translates[langUrl].picture_description[lang]"></div>
            </div>
            <div class="waiting-form__form">
                <!-- <div class="waiting-form__form__title">
                    {{ translates[langUrl].picture_title_part1[lang] }}
                    {{ blogger.firstname }} {{ blogger.lastname }}
                    ({{ blogger.social }})
                    {{ translates[langUrl].picture_title_part2[lang] }}
                </div> -->
                <template v-if="!formSended">
                    <ValidationObserver ref="validator" slim>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="waiting.name"
                                class="form"
                                :placeholder="translates[langUrl].placeholder_firstname[lang]"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BasePhoneNumberInput
                                v-model="waiting.phone"
                                @update="number($event)"
                                :required="true"
                                :error-messages="errors[0]"
                            />
                            <!-- <BaseTextField
                                v-model="waiting.phone"
                                class="form"
                                type="tel"
                                autocomplete="off"
                                :placeholder="translates[langUrl].placeholder_phone[lang]"
                                :error-messages="errors[0]"
                            /> -->
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <BaseTextField
                                v-model="waiting.email"
                                class="form"
                                :placeholder="translates[langUrl].placeholder_email[lang]"
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                    <BaseButton
                        class="button-fill"
                        style="width:100%; margin-top: 12px;"
                        @click="send">{{ translates[langUrl].button_send[lang] }}
                    </BaseButton>
                </template>
                <div v-if="formSended" class="waiting-form__sended">
                    <div class="waiting-form__sended__text">
                        {{ translates[langUrl].formSended_part_1[lang] }}
                        {{ waiting.name }}!
                        {{ translates[langUrl].formSended_part_2[lang] }}
                        {{ blogger.firstname }} {{ blogger.lastname }}!
                        {{ translates[langUrl].formSended_part_3[lang] }}
                    </div>
                    <BaseButton
                        class="button-fill"
                        style="width:100%"
                        @click="formReverse"
                    >{{ translates[langUrl].button_oneMore[lang] }}</BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';

    import BaseTextField from '../../components/common/BaseTextField.vue'
    import BaseButton from '../../components/common/BaseButton.vue'
    import BaseCheckbox from '../../components/common/BaseCheckbox.vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import MobilePreview from '../../components/common/trip/PreviewCard';
    import BasePhoneNumberInput from '../../components/common/BasePhoneInput.vue';

    import moment from '@/plugins/moment';
    moment.locale('ru');

    export default {
        name: 'waiting-form',
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            BaseTextField,
            MobilePreview,
            BaseCheckbox,
            ValidationProvider,
            ValidationObserver,
            BasePhoneNumberInput
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            header: 0,
            formSended: false,
            custom: false,
            waiting: {
                blogger: '',
                name: '',
                phone: '',
                email: ''
            },
            phoneWithCode: '',
            langUrl: '/blogger/waitingForm'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('users', {
                blogger: state => state.entity
            }),
        },
        created() {
            this.isDesktop = !isMobile();
            this.custom = this.$route.params.custom === 'blogger';
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await store.dispatch('users/fetchBySocial', { social: this.$route.params.social });
            this.waiting.blogger = this.blogger._id;
            this.waiting = {
                ...this.waiting,
            }
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async goTo(name) {
                await this.$router.push({ name });
            },
            async back() {
                await this.$router.back();
            },
            async send() {
                const valid = await this.$refs.validator.validate();
                if(valid) {
                    this.formSended = true;
                    store.commit('waitingLists/SET_ENTITY', {
                        ...this.waiting,
                        phone: this.phoneWithCode
                    });
                    store.dispatch('waitingLists/save');
                }
            },
            async formReverse() {
                this.waiting = {
                    blogger: this.blogger._id,
                    name: '',
                    phone: '',
                    email: ''
                },
                this.formSended = false;
            },
            number(event) {
                this.phoneWithCode = event.formattedNumber
            }
        },
    };
</script>

<style lang="scss">
.waiting-form {
    width: 100%;
    height: 100vh;
    display: flex;
    &__mobile {
        width: 100%;
        height: 100%;
        
        background-position: center;
        display: flex;
        flex-direction: column;
    }
    &__title {
        margin: 0 auto 45px;
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        color: #273155;
        margin-bottom: 32px;
        @media all and (max-width: 768px) {
            font-size: 24px;
            line-height: 140%;
            margin: 0 0 28px;
        }
    }
    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        @media all and (max-width: 768px) {
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 20px;
        }
    }

    &__form {
        z-index: 10;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
            @media all and (max-width: 768px) {
                width: calc(100% - 40px);
                height: fit-content;
                flex-direction: column;
                align-items: stretch;
                padding: 20px;
                background-color: #FFFFFF;
                margin: 0 20px;
                border-radius: 12px;
            }
        &__container {
            max-width: 600px;
        }
        &__logo {
            width: 290px;
            height: 33px;
            background-image: url(../../assets/Main-logo.svg);
            margin-bottom: 40px;
            @media all and (max-width: 768px) {
                width: 200px;
                height: 23px;
                background-size: contain;
            }
        }
        &__custom-logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 60px;
                @media all and (max-width: 768px) {
                    margin-bottom: 20px;
                }
            &__avatar {
                width: 300px;
                height: 300px;
                background-position: top;
                background-size: cover;
            }
        }
        &__title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #273155;
            margin-bottom: 32px;
        }
        &__text {
            z-index: 10;
            padding: 40px;
            font-family: 'Inter';
            font-style: normal;
            margin: auto 0;
            max-width: 580px;
            @media all and (max-width: 768px) {
                font-family: 'Inter';
                font-style: normal;
                margin: 0;
                padding-bottom: 0;
            }
            &__title {
                font-weight: 700;
                font-size: 30px;
                line-height: 125%;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 24px;
                    line-height: 125%;
                    margin-bottom: 16px;
                }
            }
            &__description {
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                margin-bottom: 20px;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 140%;
                    margin-bottom: 0;
                }
            }
        }
    }
    &__sended {
        max-width: 500px;
        margin: auto;
        &__text {
            margin-bottom: 15px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
        }
    }
}

</style>